<template>
  <v-card elevation="0" class="px-8">
    <v-card-title class="text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold">
      Forgot Password
    </v-card-title>
    <v-card-text>
      <v-form v-model="forgotForm" ref="forgot" lazy-validation>
        <v-text-field
          background-color="white"
          placeholder="Company Email"
          v-model="companyEmail"
          :rules="emailRules"
          class="mx-2"
        >
        </v-text-field>
        <v-btn
          @click.prevent="validateForgotForm"
          class="rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6"
          width="100%"
          color="#2EAC68"
          :disabled="!forgotForm"
        >
          Send Mail
        </v-btn>
      </v-form>
      <v-card-actions class="flex-column">
        <div class="align-self-start mt-4">
          Back to
          <router-link to="/login">Login</router-link>
        </div>
        <div
          class="align-self-start mt-8"
          v-if="whitelabelConfig.enableCommunity"
        >
          Looking for community account?
          <router-link to="/community/login">Login to community.</router-link>
        </div>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from 'firebase'
import { mapActions } from 'vuex'
import { normalizeEmail } from '../../utils/helper'
import { getWhitelabelConfig } from '../../utils/whitelabel'

export default {
  computed: {
    whitelabelConfig() {
      return getWhitelabelConfig()
    },
  },
  methods: {
    ...mapActions('customer', ['customerSignUp']),
    validateForgotForm() {
      this.$refs.forgot.validate() && this.doPasswordReset()
    },
    doPasswordReset() {
      firebase
        .auth()
        .sendPasswordResetEmail(normalizeEmail(this.companyEmail), {
          url: 'https://codedrills.io/login',
        })
        .then(() => {
          this.$store.dispatch('notifs/addNotif', {
            text: 'Sent Password Reset Mail Successfully',
            type: 'success',
          })
          this.$refs.forgot.reset()
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          this.$store.dispatch('notifs/addNotif', {
            text: 'Error occurred! Please retry after sometime!',
            type: 'error',
          })
        })
    },
  },
  created() {},
  data() {
    return {
      forgotForm: false,
      companyEmail: '',
      emailRules: [
        (v) => !!v || 'Email needed',
        (v) => /.+@.+/.test(v) || 'Email must be valid',
      ],
    }
  },
}
</script>
<style scoped></style>
